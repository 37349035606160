import React, { Component } from "react";

class AutoLogout extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.events = ["mousemove", "keypress", "click", "scroll"]; // Track user activity events
  }

  componentDidMount() {
    this.resetTimer();
    this.events.forEach((event) => window.addEventListener(event, this.resetTimer));

    window.addEventListener("beforeunload", this.handleLogout);
  }

  componentWillUnmount() {
    this.clearTimer();
    this.events.forEach((event) => window.removeEventListener(event, this.resetTimer));
    window.removeEventListener("beforeunload", this.handleLogout);
  }

  resetTimer = () => {
    this.clearTimer();
    this.timer = setTimeout(this.handleLogout, 15 * 60 * 1000); // 15 minutes
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear(); // Clear session storage (if used)
    alert("Session expired or tab closed!");
    window.location.href = "/"; // Redirect to login page
  };

  render() {
    return null; // This component doesn't render anything
  }
}

export default AutoLogout;
