import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ProductList from '../../../components/Customer/ProductList';
import ProductService from '../../../services/ProductService';
import OrderList from '../../../components/Order/OrderList';
import CustomerService from '../../../services/Customer.service';
import OrderAction from '../../../redux/actions/Order.action';
import OtherService from '../../../services/OtherService';
import Common from '../../../hoc/Common.hoc';
import CountryService from '../../../services/Country.service';
import CCAvenueService from '../../../services/CCAvenue.service';
import config from '../../../config/emrok.config';
import OrderPlacedModal from '../../../components/Order/OrderPlacedModal';
import { FREE_PEN_PRICE, FREE_PEN_QUANTITY, MAXIMUM_ORDER_QUANTITY, PRODUCT_LIMIT } from '../../../utils/Variables';
import $ from 'jquery'
import CallCenterService from '../../../services/CallCenter.service';
import { Link } from "react-router-dom";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Lightbox } from "react-modal-image";
import AdminService from '../../../services/Admin.service';
const PdfViewerWithPlugin = ({ pdfFile }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
    );
};
class PlaceOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            discountvalue: "",
            couponList: [],
            couponListFinal: [],
            selectedValue: "",
            selectedValue_2: "",
            couponDiscount: "",
            isShowApplyBtn: true,
            isShowApplyBtn_2: true,
            product_quantities: [],
            reload: true,
            paymentOrder: {
                totalPayment: "",
                partialMinPayment: "",
                deliveryPayment: "",
                totalItem: "",
                discountamount: "",
                finalPaymentAfterDiscount: "",
                appliedDiscount: ""
            },
            productQuantity: {

            },
            products: [],
            order: {
                rx: "",
                serialNo: "",
                customer: "",
                products: [{
                    product: "",
                    quantity: "",
                    price: ""
                }],
                total: "",
                deliveryStatus: "",
                orderStatus: ""
            },
            common_products: [],
            isChecked: false,
            isCheckFreePen: false,
            isCheckFreePen_2: false,
            registration: {
                "addressLine1": "",
                "pin": "",
                "country": "India",
                "state": "",
                "town": ""
            },
            countries: [{
                "name": "India",
                "label": "India",
                "title": "India",
                "value": "IN",
                "id": 123,
                "isoCode": "IN"
            }],
            states: [],
            towns: [],
            country: "India",
            states: [],
            state: "",
            towns: "",
            town: "",
            showTowns: false,
            addressLine1: "",
            paymentDetails: null,
            isOrderplaced: false,
            orderDetails: null,
            schemeDetails: [],
            freepenQuantity: 0,
            freepenPrice: 0,
            freepenQuantity_2: 0,
            freepenPrice_2: 0,
            schemeCouponList: [],
            titleName: "Free Gift",
            titleName_2: "Free Gift",
            coupon_code: null,
            selectedSchemeProduct: [],
            selectedSchemeProduct_2: [],
            paymentProcess: false,
            selectedFile: null,
            fileUploadError: '',
            imagename: "",
            color: 'red',
            prescription: null,
            isLoading: false,
            selectedFile: [],
            image: null,
            numPages: null,
            is_applied: false,
            is_applied_2: false,
            schemeListOfAllProduct: [],
            couponListOfAllProduct: []
        }
        this.productDecrement = this.productDecrement.bind(this);
        this.productIncrement = this.productIncrement.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
        this.fileInputRef = React.createRef();
        this.toggleLightbox = this.toggleLightbox.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            common_products: props.ProductReducer ? props.ProductReducer.common_products ? props.ProductReducer.common_products : [] : [],
            paymentDetails: props.OrderReducer ? props.OrderReducer.paymentDetails ? props.OrderReducer.paymentDetails : null : null,
            prescription: props.CustomerReducer.prescription || null
        }
    }

    componentWillUnmount = () => {
        CallCenterService.hideLoader()
    }

    // Callback function to update the selectedValue state
    handleSelectChange = (item) => {
        console.log(item, "selectedValue");
        if (item.material == '403820') {
            this.setState({ selectedValue_2: item });
        } else {
            this.setState({ selectedValue: item });
        }
    };

    calculateScheme = (materialId) => {
        console.log('apply coupon 3', materialId);
        // alert();
        console.log('apply coupon 3');
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, selectedValue_2, couponList, couponListFinal, schemeCouponList, freepenPrice, freepenPrice_2 } = this.state;
        console.log(paymentOrder, "paymentOrder");
        console.log('apply coupon 3 selectedValue', selectedValue);
        console.log('apply coupon 3 selectedValue_2', selectedValue_2);
        console.log('123334', productQuantity);
        let _products = [...products]
        let total_free_pen = 0, total_free_pen_price = 0, seelctedSchemeproduct = [], selectedSchemeProduct_2 = []
        let total_free_pen_2 = 0, total_free_pen_price_2 = 0;
        if (selectedValue && selectedValue.material == materialId) {
            // alert("23")
            console.log(_products.length, selectedValue, "test4productlength");
            for (let i = 0; i < _products.length; i++) {
                console.log("test4");
                if (_products[i].material == selectedValue.material) {

                    let quantity = product_quantities[i].quantity

                    console.log(quantity, selectedValue.purchase_quantity, 'test4quantity');
                    if (quantity >= selectedValue.purchase_quantity) {
                        console.log(quantity, "test4quantity");
                        if (quantity != 0) {
                            console.log("test5");
                            seelctedSchemeproduct.push(_products[i].name);

                            if (selectedValue.increase_quantity == 'YES') {
                                let free_pen = 0;
                                if (selectedValue.scheme_code == "SCHEME1") {
                                    let minQuantity = 5;
                                    let maxQuantity = 10;

                                    free_pen = (quantity / minQuantity) * selectedValue.free_quantity;
                                } else {

                                    free_pen = Math.floor(quantity / selectedValue.purchase_quantity) * selectedValue.free_quantity
                                }


                                let price = +selectedValue.free_value * free_pen
                                total_free_pen += free_pen
                                total_free_pen_price += price
                                console.log(total_free_pen_price, "total_free_pen_price");
                            }
                            else {


                                let free_pen = selectedValue.free_quantity
                                let price = +selectedValue.free_value * free_pen
                                total_free_pen += free_pen
                                total_free_pen_price += price
                            }
                        } else {
                            console.log("test6");
                            console.log(selectedValue, "selectedValue5000");
                            seelctedSchemeproduct = []
                            this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedValue: "", selectedSchemeProduct: [] })

                            setTimeout(() => {
                                this.setState({ selectedValue: "" })
                            }, 500);
                        }

                        this.setState({ isShowApplyBtn: false })
                    } else if (quantity == 0) {
                        seelctedSchemeproduct = []
                        this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedValue: "", selectedSchemeProduct: [] })
                    }
                }
            }

            if (selectedValue.increase_quantity == 'YES') {
                if (selectedValue.scheme_code == 'SCHEME1') {

                    // alert('in scheme1');
                    // alert("f1");
                    const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                    console.log(paymentOrder, "in scheme1");

                    // alert(finalPaymentAfterDiscount);
                    // alert(total_free_pen_price)
                    let afterCouponDiscount = finalPaymentAfterDiscount;
                    // if (selectedValue.material == materialId) {
                    afterCouponDiscount = finalPaymentAfterDiscount - total_free_pen_price;
                    // }
                    console.log('apply coupon total_free_pen_price 4', total_free_pen_price);

                    console.log('apply coupon 4');

                    console.log('apply coupon 5' + afterCouponDiscount);
                    console.log('apply coupon 5 freepenPrice_2', freepenPrice_2);

                    if (selectedValue_2.increase_quantity == 'YES') {
                        if (selectedValue_2.scheme_code == 'SCHEME3') {
                            afterCouponDiscount = afterCouponDiscount - freepenPrice_2;
                        }
                    }
                    // if (selectedValue_2) {
                    //     if (selectedValue_2.increase_quantity == 'YES') {
                    //         if (selectedValue_2.scheme_code == 'SCHEME1') {
                    //             afterCouponDiscount = afterCouponDiscount + freepenPrice_2;
                    //         }
                    //     }
                    // }
                    let newPaymentOrder = paymentOrder;

                    console.log(afterCouponDiscount, "in scheme1 afterCouponDiscount")
                    newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                    // setTimeout(() => {
                    this.setState({ paymentOrder: newPaymentOrder })
                    // }, 500);
                    console.log('apply coupon 5 newpaymentorder' + newPaymentOrder);

                }
            }
            this.setState({ selectedSchemeProduct: seelctedSchemeproduct })
            console.log(total_free_pen, total_free_pen_price, "in scheme1 jasdhfjklashjkh");
            this.setState({ freepenQuantity: total_free_pen, freepenPrice: total_free_pen_price, isCheckFreePen: true, })
        }

        //for 2nd product
        console.log(this.state.paymentOrder, "paymentOrder100000")

        if (selectedValue_2 && selectedValue_2.material == materialId) {
            // alert("24")
            console.log(_products.length, selectedValue_2, "test4productlength");
            for (let i = 0; i < _products.length; i++) {
                // alert("245")
                console.log("test4");
                if (_products[i].material == selectedValue_2.material) {
                    // alert("2456");
                    let quantity = product_quantities[i].quantity
                    console.log(quantity, selectedValue_2.purchase_quantity, 'test4quantity');
                    if (quantity >= selectedValue_2.purchase_quantity) {
                        console.log(quantity, "test4quantity");
                        if (quantity != 0) {
                            console.log("test5");
                            selectedSchemeProduct_2.push(_products[i].name);

                            if (selectedValue_2.increase_quantity == 'YES') {
                                // alert("1");
                                let free_pen = 0;
                                if (selectedValue_2.scheme_code == "SCHEME3") {
                                    let minQuantity = 6;
                                    let maxQuantity = 12;
                                    // if (quantity == 6) {
                                    //     free_pen = 2
                                    // } if (quantity == 12) {
                                    //     free_pen = 4
                                    // }
                                    free_pen = (quantity / minQuantity) * selectedValue_2.free_quantity;

                                } else {
                                    free_pen = Math.floor(quantity / selectedValue_2.purchase_quantity) * selectedValue_2.free_quantity
                                }
                                console.log(free_pen, quantity, selectedValue_2.purchase_quantity, selectedValue_2.free_quantity, 55555);



                                let price = +selectedValue_2.free_value * free_pen
                                total_free_pen_2 += free_pen
                                total_free_pen_price_2 += price
                                console.log(total_free_pen_price_2, "total_free_pen_price");
                            }
                            else {
                                // alert("2");
                                let free_pen = selectedValue_2.free_quantity
                                let price = +selectedValue_2.free_value * free_pen
                                total_free_pen_2 += free_pen
                                total_free_pen_price_2 += price
                            }
                        } else {
                            // alert("3");
                            console.log("test6");
                            console.log(selectedValue_2, "selectedValue5000");
                            selectedSchemeProduct_2 = []
                            this.setState({ freepenQuantity_2: 0, freepenPrice_2: 0, isCheckFreePen_2: false, isShowApplyBtn_2: true, selectedValue_2: "", selectedSchemeProduct_2: [] })

                            setTimeout(() => {
                                this.setState({ selectedValue_2: "" })
                            }, 500);
                        }

                        this.setState({ isShowApplyBtn_2: false })
                    } else if (quantity == 0) {
                        selectedSchemeProduct_2 = []
                        this.setState({ freepenQuantity_2: 0, freepenPrice_2: 0, isCheckFreePen_2: false, isShowApplyBtn_2: true, selectedValue_2: "", selectedSchemeProduct_2: [] })
                    }
                }
            }

            if (selectedValue_2.increase_quantity == 'YES') {
                if (selectedValue_2.scheme_code == 'SCHEME3') {
                    console.log('in scheme1');
                    // alert("SCHEME3");
                    // alert('f2');
                    const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                    console.log(paymentOrder, "in scheme1");
                    // alert(finalPaymentAfterDiscount);
                    // alert(total_free_pen_price_2)
                    console.log('apply coupon 6');
                    console.log('apply coupon 6 finalPaymentAfterDiscount', finalPaymentAfterDiscount);
                    console.log('apply coupon 6 total_free_pen_price_2', total_free_pen_price_2);
                    let afterCouponDiscount = finalPaymentAfterDiscount;
                    // if (selectedValue_2.material == materialId) {
                    console.log('apply coupon 6 finalPaymentAfterDiscount in');
                    afterCouponDiscount = finalPaymentAfterDiscount - total_free_pen_price_2;
                    // }

                    if (selectedValue && selectedValue.increase_quantity == 'YES') {
                        if (selectedValue.scheme_code == 'SCHEME1') {
                            afterCouponDiscount = afterCouponDiscount - freepenPrice;
                        }
                    }



                    // alert(afterCouponDiscount + "afterCouponDiscount")
                    // if (selectedValue) {
                    //     if (selectedValue.increase_quantity == 'YES') {
                    //         if (selectedValue.scheme_code == 'SCHEME1') {
                    //             afterCouponDiscount = afterCouponDiscount + freepenPrice;
                    //         }
                    //     }
                    // }
                    console.log('apply coupon 7' + afterCouponDiscount);
                    let newPaymentOrder = paymentOrder;
                    console.log(afterCouponDiscount, "in scheme1 afterCouponDiscount")
                    newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                    // setTimeout(() => {
                    this.setState({ paymentOrder: newPaymentOrder })
                    // }, 500);
                    console.log('apply coupon 8', newPaymentOrder);
                }
            }

            this.setState({ selectedSchemeProduct_2: selectedSchemeProduct_2 })
            console.log(total_free_pen_2, total_free_pen_price_2, "in scheme1 jasdhfjklashjkh");
            this.setState({ freepenQuantity_2: total_free_pen_2, freepenPrice_2: total_free_pen_price_2, isCheckFreePen_2: true, })
        }
    }

    calculateCoupon = () => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, selectedValue_2, couponList, couponListFinal, schemeCouponList } = this.state;
        console.log('123334', paymentOrder);

        if (selectedValue) {
            console.log(selectedValue, "test100selectedValue")
            let value = selectedValue ? selectedValue.name ? selectedValue.name : null : null

            console.log();
            const newProducts = products.map((product, i) => {
                return {
                    product_id: product._id,
                    price: product.price,
                    quantity: product_quantities[i].quantity,
                    name: product.name
                }
            })
            const _products = newProducts.filter(product => product.quantity != 0)
            if (!_products.length) {
                this.setState({ selectedValue: "", isShowApplyBtn: true, couponDiscount: "" })
                this.props.hoc.customAlert("Please select a product for applying coupon", false)
            } else if (value == "") {
                this.props.hoc.customAlert("Please select a valid coupon", false)
            } else if (_products.length == 1) {
                console.log("test1001");


                let productId = _products[0].product_id
                const productById = products.find((product) => {
                    return product._id == productId
                })


                if (productById) {
                    console.log("test1002")

                    console.log(productById.material, productById, "102productById.material")
                    // const isSchemeExist = schemeCouponList.find((scheme) => {
                    //     return scheme.material === productById.material
                    // })
                    // console.log(isSchemeExist, "101isSchemeExist")

                    console.log(productById, selectedValue, 'test1selectedval');

                    if (productById.material != selectedValue.material) {
                        console.log("test1");
                        console.log('test1003')
                        this.setState({ selectedValue: '', isShowApplyBtn: true, couponDiscount: "" })
                        this.props.hoc.customAlert("The current Coupon/Scheme applied is not applicable on the selected product.", false)
                    } else {
                        console.log("test1004");
                        // alert('f3');
                        const selectedCoupon = couponListFinal.filter(item => item.code === value)
                        console.log(selectedCoupon, "selectedCoupon 123");
                        console.log(paymentOrder, "paymentOrder 1002")
                        console.log(paymentOrder.finalPaymentAfterDiscount, "paymentOrder.finalPaymentAfterDiscount 1002")
                        const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                        console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 1002");
                        const couponDiscount = finalPaymentAfterDiscount * (selectedCoupon.length > 0 ? (selectedCoupon[0].value / 100) : 0)
                        console.log(couponDiscount, "65656dd565");
                        const afterCouponDiscount = finalPaymentAfterDiscount - couponDiscount;
                        let newPaymentOrder = paymentOrder;
                        // alert("afterCouponDiscount" + afterCouponDiscount)
                        newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                        console.log(newPaymentOrder, "newPaymentOrder 123");
                        this.setState({ paymentOrder: newPaymentOrder })
                        this.setState({ couponDiscount: couponDiscount });
                        this.setState({ isShowApplyBtn: false });
                        console.log(selectedCoupon[0], "selectedCoupon 123")
                        console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 123")
                        console.log(couponDiscount, "couponDiscount 123")
                        console.log(afterCouponDiscount, "afterCouponDiscount 123")


                    }

                }

                console.log(productById, "101productById");

            } else {
                console.log("test3");
                console.log("test1005");
                // alert("f4")
                console.log(_products, couponListFinal, value, "test10055");
                const selectedCoupon = couponListFinal.filter(item => item.code === value)
                console.log(selectedCoupon, "selectedCoupon 123");
                console.log(paymentOrder, "paymentOrder 1002")
                console.log(paymentOrder.finalPaymentAfterDiscount, "paymentOrder.finalPaymentAfterDiscount 1002")

                const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                // alert(finalPaymentAfterDiscount, "finalPaymentAfterDiscount_2");
                console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 1002");
                let val = (selectedCoupon.length > 0 ? (selectedCoupon[0].value / 100) : 1);
                // alert(val);
                // alert(selectedCoupon)
                const couponDiscount = finalPaymentAfterDiscount * (selectedCoupon.length > 0 ? (selectedCoupon[0].value / 100) : 0)
                console.log(couponDiscount, "65656dd565");
                const afterCouponDiscount = finalPaymentAfterDiscount - couponDiscount;
                let newPaymentOrder = paymentOrder;
                // alert("afterCouponDiscount_2" + afterCouponDiscount);
                newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                console.log(newPaymentOrder, "newPaymentOrder 123");
                this.setState({ paymentOrder: newPaymentOrder })
                this.setState({ couponDiscount: couponDiscount });
                this.setState({ isShowApplyBtn: false });
                console.log(selectedCoupon[0], "selectedCoupon 123")
                console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 123")
                console.log(couponDiscount, "couponDiscount 123")
                console.log(afterCouponDiscount, "afterCouponDiscount 123")
            }
        }

        //2nd product

        if (selectedValue_2) {
            // alert('110');
            console.log(selectedValue_2, "test100selectedValue")
            let value = selectedValue_2 ? selectedValue_2.name ? selectedValue_2.name : null : null

            console.log();
            const newProducts = products.map((product, i) => {
                return {
                    product_id: product._id,
                    price: product.price,
                    quantity: product_quantities[i].quantity,
                    name: product.name
                }
            })
            const _products = newProducts.filter(product => product.quantity != 0)
            if (!_products.length) {
                this.setState({ selectedValue_2: "", isShowApplyBtn_2: true, couponDiscount: "" })
                this.props.hoc.customAlert("Please select a product for applying coupon", false)
            } else if (value == "") {
                this.props.hoc.customAlert("Please select a valid coupon", false)
            } else if (_products.length == 1) {
                console.log("test1001");


                let productId = _products[0].product_id
                const productById = products.find((product) => {
                    return product._id == productId
                })


                if (productById) {
                    console.log("test1002")

                    console.log(productById.material, productById, "102productById.material")
                    // const isSchemeExist = schemeCouponList.find((scheme) => {
                    //     return scheme.material === productById.material
                    // })
                    // console.log(isSchemeExist, "101isSchemeExist")

                    console.log(productById, selectedValue_2, 'test1selectedval');

                    if (productById.material != selectedValue_2.material) {
                        console.log("test1");
                        console.log('test1003')
                        this.setState({ selectedValue_2: '', isShowApplyBtn_2: true, couponDiscount: "" })
                        this.props.hoc.customAlert("The current Coupon/Scheme applied is not applicable on the selected product.", false)
                    } else {
                        console.log("test1004");
                        // alert('f5');
                        const selectedCoupon = couponListFinal.filter(item => item.code === value)
                        console.log(selectedCoupon, "selectedCoupon 123");
                        console.log(paymentOrder, "paymentOrder 1002")
                        console.log(paymentOrder.finalPaymentAfterDiscount, "paymentOrder.finalPaymentAfterDiscount 1002")
                        const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                        console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 1002");
                        const couponDiscount = finalPaymentAfterDiscount * (selectedCoupon.length > 0 ? (selectedCoupon[0].value / 100) : 0)
                        console.log(couponDiscount, "65656dd565");
                        const afterCouponDiscount = finalPaymentAfterDiscount - couponDiscount;
                        let newPaymentOrder = paymentOrder;
                        newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                        console.log(newPaymentOrder, "newPaymentOrder 123");
                        this.setState({ paymentOrder: newPaymentOrder })
                        this.setState({ couponDiscount: couponDiscount });
                        this.setState({ isShowApplyBtn_2: false });
                        console.log(selectedCoupon[0], "selectedCoupon 123")
                        console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 123")
                        console.log(couponDiscount, "couponDiscount 123")
                        console.log(afterCouponDiscount, "afterCouponDiscount 123")
                    }
                }
                console.log(productById, "101productById");

            } else {
                console.log("test3");
                console.log("test1005");

                console.log(_products, couponListFinal, value, "test10055");
                const selectedCoupon = couponListFinal.filter(item => item.code === value)
                console.log(selectedCoupon, "selectedCoupon 123");
                console.log(paymentOrder, "paymentOrder 1002")
                console.log(paymentOrder.finalPaymentAfterDiscount, "paymentOrder.finalPaymentAfterDiscount 1002")
                const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 1002");
                const couponDiscount = finalPaymentAfterDiscount * (selectedCoupon.length > 0 ? (selectedCoupon[0].value / 100) : 0)
                console.log(couponDiscount, "65656dd565");
                const afterCouponDiscount = finalPaymentAfterDiscount - couponDiscount;
                let newPaymentOrder = paymentOrder;
                newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                console.log(newPaymentOrder, "newPaymentOrder 123");
                this.setState({ paymentOrder: newPaymentOrder })
                this.setState({ couponDiscount: couponDiscount });
                this.setState({ isShowApplyBtn_2: false });
                console.log(selectedCoupon[0], "selectedCoupon 123")
                console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 123")
                console.log(couponDiscount, "couponDiscount 123")
                console.log(afterCouponDiscount, "afterCouponDiscount 123")
            }
        }
    }

    applyCoupon = (materialID_applied, productId) => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, selectedValue_2, couponList, couponListFinal, schemeCouponList } = this.state;
        console.log(selectedValue, schemeCouponList, selectedValue_2, "101selectedValue123");
        console.log(couponList, "101couponList");
        console.log(product_quantities, "101product_quantities")
        console.log(products, "101products")

        console.log(selectedValue, "selectedValue000");
        console.log(selectedValue_2, "selectedValue000_2");
        console.log(schemeCouponList, "schemeCouponListselectedValue000_2");

        if (selectedValue && materialID_applied === '404706') {
            //check scheme is exist or not
            ProductService.checkSchemeExistOrNot(selectedValue).then(data => {

                console.log(data, "checkdata");
                if (data.data.data.length > 0) {
                    this.setState({ is_applied: true });

                    console.log('12333');
                    if (selectedValue.hasOwnProperty("increase_quantity")) {

                        const newProducts = products.map((product, i) => {

                            // if (selectedValue.scheme_code == 'SCHEME1') {
                            //     product_quantities[i].quantity = product_quantities[i].quantity - 1;
                            // }
                            return {
                                product_id: product._id,
                                price: product.price,
                                quantity: product_quantities[i].quantity,
                                name: product.name
                            }
                        })
                        const _products = newProducts.filter(product => product.quantity != 0)
                        console.log(_products, '101products');

                        if (!_products.length) {
                            this.setState({ selectedValue: '', isShowApplyBtn: true })
                            this.props.hoc.customAlert("Please select a product for applying scheme", false)
                        } else if (_products.length == 1) {
                            let productId = _products[0].product_id
                            const productById = products.find((product) => {
                                return product._id == productId
                            })
                            if (productById) {
                                console.log(productById.material, productById, "101productById.material")
                                // const isSchemeExist = schemeCouponList.find((scheme) => {
                                //     return scheme.material === productById.material
                                // })

                                // console.log(isSchemeExist, "101isSchemeExist")

                                if (productById.material != selectedValue.material) {
                                    console.log('101scheme1')
                                    this.setState({ selectedValue: '' })
                                    this.props.hoc.customAlert("The current Coupon/Scheme applied is not applicable on the selected product.", false)
                                } else {
                                    let titleName = "";
                                    if (selectedValue.name.includes("Pen")) {
                                        titleName = 'Free Pen'
                                    } else {
                                        titleName = 'Free Cartridge'
                                    }
                                    this.setState({ titleName: titleName })
                                    // logic for scheme
                                    console.log('apply coupon 1');
                                    this.calculateScheme(materialID_applied)
                                }

                            }

                            console.log(productById, "101productById");

                        } else {
                            let titleName = "";
                            if (selectedValue.name.includes("Pen")) {
                                titleName = 'Free Pen'
                            } else {
                                titleName = 'Free Cartridge'
                            }
                            this.setState({ titleName: titleName })
                            // logic for scheme
                            console.log('apply coupon 2');
                            this.calculateScheme(materialID_applied)

                        }

                    }
                    else {

                        // this.calculateCoupon()

                    }
                } else {
                    this.props.hoc.customAlert('The scheme you applied is changed.');
                    const productIndex = products.findIndex((product) => {
                        return product._id == productId
                    })
                    this.getSchemeAndCoupon(products[productIndex]);
                    return false;
                }
            })
        }
        ///// 2nd product

        if (selectedValue_2 && materialID_applied === '403820') {

            ProductService.checkSchemeExistOrNot(selectedValue_2).then(data => {

                console.log(data, "checkdata");
                if (data.data.data.length > 0) {
                    this.setState({ is_applied_2: true });
                    console.log('12333');
                    if (selectedValue_2.hasOwnProperty("increase_quantity")) {
                        const newProducts = products.map((product, i) => {
                            return {
                                product_id: product._id,
                                price: product.price,
                                quantity: product_quantities[i].quantity,
                                name: product.name
                            }
                        })
                        const _products = newProducts.filter(product => product.quantity != 0)
                        console.log(_products, '101products');
                        if (!_products.length) {
                            this.setState({ selectedValue_2: '', isShowApplyBtn_2: true })
                            this.props.hoc.customAlert("Please select a product for applying scheme", false)
                        } else if (_products.length == 1) {
                            let productId = _products[0].product_id
                            const productById = products.find((product) => {
                                return product._id == productId
                            })
                            if (productById) {
                                console.log(productById.material, productById, "101productById.material")
                                if (productById.material != selectedValue_2.material) {
                                    console.log('101scheme1')
                                    this.setState({ selectedValue_2: '' })
                                    this.props.hoc.customAlert("The current Coupon/Scheme applied is not applicable on the selected product.", false)
                                } else {
                                    let titleName_2 = "";
                                    if (selectedValue_2.name.includes("Pen")) {
                                        titleName_2 = 'Free Pen'
                                    } else {
                                        titleName_2 = 'Free Cartridge'
                                    }
                                    this.setState({ titleName_2: titleName_2 })
                                    // logic for scheme
                                    this.calculateScheme(materialID_applied)
                                }
                            }
                            console.log(productById, "101productById");
                        } else {
                            let titleName_2 = "";
                            if (selectedValue_2.name.includes("Pen")) {
                                titleName_2 = 'Free Pen'
                            } else {
                                titleName_2 = 'Free Cartridge'
                            }
                            this.setState({ titleName_2: titleName_2 })
                            // logic for scheme
                            this.calculateScheme(materialID_applied)
                        }
                    }
                    else {
                        // this.calculateCoupon()
                    }

                } else {
                    this.props.hoc.customAlert('The scheme you applied is changed.');
                    const productIndex = products.findIndex((product) => {
                        return product._id == productId
                    })
                    this.getSchemeAndCoupon(products[productIndex]);
                    return false;
                }
                // if (data.data.success && data.data.data.response.length > 0) {
                //     // console.log(data.data.data.response[0],"discountdata");
                //     // this.setState({ discountvalue: data.data.data.response[0].discountValue });
                //     this.setState({ discountvalue: 0 });
                // }
            })


            //check scheme is exist or not 


        }
    }

    removeCoupon = (materialID_applied, productId) => {
        // alert(materialID_applied);
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, selectedValue_2, couponList, couponDiscount, freepenPrice, freepenPrice_2, coupontype } = this.state;
        console.log(selectedValue, "test selectedValue1233");
        console.log(selectedValue_2, "test selectedValue_21233");
        if (selectedValue && materialID_applied === '404706') {
            this.setState({ is_applied: false });
            if (selectedValue.hasOwnProperty("increase_quantity")) {
                // logic for scheme
                console.log('12333400');

                console.log("test7");

                if (selectedValue.increase_quantity == 'YES') {
                    console.log("test8");
                    console.log(paymentOrder, "test paymentOrder");

                    if (selectedValue.scheme_code == 'SCHEME1') {
                        // alert(coupontype)
                        let newPaymentOrder = paymentOrder;
                        const finalPaymentAfterDiscount = paymentOrder.totalPayment - (selectedValue_2 != "" && selectedValue_2.scheme_code == "SCHEME4" ? 0 : freepenPrice_2);
                        newPaymentOrder['finalPaymentAfterDiscount'] = finalPaymentAfterDiscount;
                        this.setState({ paymentOrder: newPaymentOrder })

                    }
                    this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedValue: "", selectedSchemeProduct: [] })

                } else {
                    console.log("test9");
                    this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedValue: "", selectedSchemeProduct: [] })
                }

            } else {

                console.log("test10");
                // alert(couponDiscount)
                const finalPaymentAfterDiscount = paymentOrder.finalPaymentAfterDiscount;
                const afterCouponDiscount = finalPaymentAfterDiscount + couponDiscount;
                let newPaymentOrder = paymentOrder;
                newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                console.log(finalPaymentAfterDiscount, "remove finalPaymentAfterDiscount");
                console.log(afterCouponDiscount, "remove afterCouponDiscount");
                console.log(newPaymentOrder, "remove newPaymentOrder");
                console.log(couponDiscount, "remove couponDiscount");
                console.log(newPaymentOrder, "remove newPaymentOrder 123");
                if (paymentOrder.totalPayment <= 0) {
                    newPaymentOrder['finalPaymentAfterDiscount'] = 0;
                }
                this.setState({ paymentOrder: newPaymentOrder })
                this.setState({ couponDiscount: "" });
                this.setState({ isShowApplyBtn: true });
                this.setState({ selectedValue: "" });

            }
        }

        // 2nd product
        if (selectedValue_2 && materialID_applied === '403820') {
            this.setState({ is_applied_2: false });
            if (selectedValue_2.hasOwnProperty("increase_quantity")) {
                // logic for scheme
                console.log('12333400');
                // alert("11");
                console.log("test7");

                if (selectedValue_2.increase_quantity == 'YES') {
                    // alert("12");
                    console.log("test8");
                    console.log(paymentOrder, "test paymentOrder");

                    if (selectedValue_2.scheme_code == 'SCHEME3') {
                        // alert("coupontype");
                        // alert(coupontype);
                        console.log(selectedValue, "selectedValue22");
                        let newPaymentOrder = paymentOrder;
                        const finalPaymentAfterDiscount = paymentOrder.totalPayment - (selectedValue != "" && selectedValue.scheme_code == "SCHEME2" ? 0 : freepenPrice);
                        newPaymentOrder['finalPaymentAfterDiscount'] = finalPaymentAfterDiscount;
                        this.setState({ paymentOrder: newPaymentOrder })

                    }
                    // alert("14");
                    this.setState({ freepenQuantity_2: 0, freepenPrice_2: 0, isCheckFreePen_2: false, isShowApplyBtn_2: true, selectedValue_2: "", selectedSchemeProduct_2: [] })

                } else {
                    // alert("15");
                    console.log("test9");
                    this.setState({ freepenQuantity_2: 0, freepenPrice_2: 0, isCheckFreePen_2: false, isShowApplyBtn_2: true, selectedValue_2: "", selectedSchemeProduct_2: [] })
                }

            } else {
                // alert("16");
                // alert("coupondiscount");
                // alert(couponDiscount)
                console.log("test10");
                const finalPaymentAfterDiscount = paymentOrder.finalPaymentAfterDiscount;
                const afterCouponDiscount = finalPaymentAfterDiscount + couponDiscount;
                let newPaymentOrder = paymentOrder;
                newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                console.log(finalPaymentAfterDiscount, "remove finalPaymentAfterDiscount");
                console.log(afterCouponDiscount, "remove afterCouponDiscount");
                console.log(newPaymentOrder, "remove newPaymentOrder");
                console.log(couponDiscount, "remove couponDiscount");
                console.log(newPaymentOrder, "remove newPaymentOrder 123");
                if (paymentOrder.totalPayment <= 0) {
                    newPaymentOrder['finalPaymentAfterDiscount'] = 0;
                }
                this.setState({ paymentOrder: newPaymentOrder })
                this.setState({ couponDiscount: "" });
                this.setState({ isShowApplyBtn_2: true });
                this.setState({ selectedValue_2: "" });

            }
        }



    }

    productPriceCalCulate = () => {
        const { products, selectedValue, selectedValue_2, couponListFinal, freepenPrice, freepenQuantity, freepenPrice_2, freepenQuantity_2 } = this.state;
        console.log(products, "ooooooo 123");
        let product_quantities = this.state.product_quantities
        let totalPayment = 0;
        let totalItem = 0;
        let discountamount = 0;
        let finalPaymentAfterDiscount = 0;

        let totalPayment_2 = 0;
        let totalItem_2 = 0;
        let discountamount_2 = 0;
        let finalPaymentAfterDiscount_2 = 0;

        products.forEach((product, i) => {
            const price = parseFloat(product.price);
            totalPayment = totalPayment + price * product_quantities[i]?.quantity;
            // totalPayment = totalPayment + price * product.quantity;
            totalItem = totalItem + product_quantities[i]?.quantity;
            // totalItem = totalItem + product.quantity;
        })
        console.log(totalItem, '<<<<<<<<<<');

        const partialMinPayment = totalPayment * (20 / 100);
        let appliedDiscount = 0;
        if (selectedValue.hasOwnProperty("increase_quantity")) {
            appliedDiscount = this.state.discountvalue != "" ? this.state.discountvalue : 0;
            console.log(appliedDiscount, "appliedDiscount if 1002");
        } else {
            if (selectedValue.material === "404706") {
                console.log(selectedValue, "selectedValue111 12355")
                let value = selectedValue ? selectedValue.name ? selectedValue.name : null : null
                const selectedCoupon = value != null ? couponListFinal.filter(item => item.code === value) : []
                appliedDiscount = selectedCoupon.length > 0 ? selectedCoupon[0].value != "" ? selectedCoupon[0].value : 0 : 0;
                console.log(appliedDiscount, "appliedDiscount else 1002");
            }
        }

        if (selectedValue_2.hasOwnProperty("increase_quantity")) {
            appliedDiscount = this.state.discountvalue != "" ? this.state.discountvalue : 0;
            console.log(appliedDiscount, "appliedDiscount if 1002");
        } else {
            if (selectedValue_2.material === "403820") {
                console.log(selectedValue_2, "selectedValue111 12355")
                let value = selectedValue_2 ? selectedValue_2.name ? selectedValue_2.name : null : null
                const selectedCoupon = value != null ? couponListFinal.filter(item => item.code === value) : []
                appliedDiscount = selectedCoupon.length > 0 ? selectedCoupon[0].value != "" ? selectedCoupon[0].value : 0 : 0;
                console.log(appliedDiscount, "appliedDiscount else 1002");
            }
        }

        console.log(appliedDiscount, totalPayment, finalPaymentAfterDiscount, discountamount, "orderscheme");

        if (appliedDiscount != 0) {

            discountamount = totalPayment * (appliedDiscount / 100);
            finalPaymentAfterDiscount = totalPayment - discountamount;
            console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount if 1002");
        } else {
            discountamount = 0;
            finalPaymentAfterDiscount = totalPayment;

            console.log(selectedValue, "finalPaymentAfterDiscount selectedValue");

            if (selectedValue.hasOwnProperty("increase_quantity")) {


                if (selectedValue.scheme_code == 'SCHEME1') {
                    console.log('paymentorder 10022')
                    finalPaymentAfterDiscount = totalPayment - freepenPrice;

                    console.log('apply coupon 9', finalPaymentAfterDiscount)
                }
            }

            if (selectedValue_2.hasOwnProperty("increase_quantity")) {
                if (selectedValue_2.scheme_code == 'SCHEME3') {
                    console.log('paymentorder 10022')
                    finalPaymentAfterDiscount = totalPayment - freepenPrice_2;
                    console.log('apply coupon 10', finalPaymentAfterDiscount)
                }
            }

            console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount else 1002");

        }

        console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount_2");

        const deliveryPayment = totalPayment - partialMinPayment;
        console.log(totalPayment, partialMinPayment, deliveryPayment, totalItem, discountamount, finalPaymentAfterDiscount, appliedDiscount, freepenPrice, freepenQuantity, 'paymentorder 1002');

        this.setState({ paymentOrder: { totalPayment, partialMinPayment, deliveryPayment, totalItem, discountamount, finalPaymentAfterDiscount, appliedDiscount } })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.products != this.state.products) {
            this.productPriceCalCulate();
        }
    }

    componentDidMount = async () => {
        console.log("12345566");
        const allOrders = this.props.OrderReducer.selectedOrders;

        const pagename = this.props.CustomerReducer.pagename;
        console.log(pagename, "pagename");
        console.log(allOrders, "reorderProducts")
        const reorderProducts = allOrders ? allOrders.products : [];
        console.log(allOrders, 123456666);
        let product_quantities = []
        let finalData = [];
        const newProductQuantity = {}
        let payload = {
            type: "productlist"
        }
        ProductService.getProductList(payload).then(res => {
            console.log(res, "response1233")
            // return
            if (res && res.data && res.data.success) {
                if (res.data.data) {
                    const products = res.data.data;
                    const allProducts = res.data.data;
                    console.log(allProducts, "filteredItem_productallProducts");
                    for (let i = 0; i < products.length; i++) {
                        let product = products[i]
                        console.log(product, 999);
                        newProductQuantity[product._id] = product.quantity;
                    }
                    for (let i = 0; i < products.length; i++) {

                        if (reorderProducts !== undefined && reorderProducts !== null && Object.keys(reorderProducts).length > 0) {
                            console.log(reorderProducts, "reorderProducts")
                            console.log(products, "reorderProductsproducts")
                            const filteredItem = reorderProducts.find(item => item.product_id === products[i]._id);
                            console.log(filteredItem, "reorderProductsfilteredItem")
                            if (filteredItem) {
                                console.log(filteredItem, 'filteredItem_productfilteredItem');
                                product_quantities.push({
                                    item: i + 1,
                                    quantity: filteredItem.quantity
                                })

                                const filteredItem_product = allProducts.filter(item => item._id === filteredItem._id);
                                console.log(filteredItem_product, "filteredItem_product");
                                console.log(products[i], "products[i]filteredItem_product");

                                this.getSchemeAndCoupon(products[i]);


                            } else {
                                product_quantities.push({
                                    item: i + 1,
                                    quantity: 0
                                })
                            }
                        } else {
                            product_quantities.push({
                                item: i + 1,
                                quantity: 0
                            })
                        }


                    }
                    this.setState({ products: products, productQuantity: newProductQuantity, product_quantities })
                }
            }
        }).catch(e => {
            console.log("e>>>>>>>>>>>>", e)
        })
        OtherService.getDiscountAmount().then(data => {
            if (data.data.success && data.data.data.response.length > 0) {
                // console.log(data.data.data.response[0],"discountdata");
                // this.setState({ discountvalue: data.data.data.response[0].discountValue });
                this.setState({ discountvalue: 0 });
            }
        })

        // OtherService.getCouponList().then(data => {
        //     console.log(data, "coupon list");
        //     if (data.data.success && data.data.data.response.length > 0) {
        //         for (let index = 0; index < data.data.data.response.length; index++) {
        //             finalData[index] = {
        //                 "name": data.data.data.response[index].code,
        //                 "label": data.data.data.response[index].code,
        //                 "value": data.data.data.response[index].code,
        //                 "id": data.data.data.response[index]._id,
        //                 "material": data.data.data.response[index].material
        //             };
        //         }
        //         this.setState({ couponList: finalData, couponListFinal: data.data.data.response }, () => {
        //             this.setSchemeCouponList()
        //         })
        //     }
        // })

        // OtherService.getSchemeData().then(data => {
        //     console.log(data, "data1233");
        //     if (data.data.success && data.data.data.details.length) {
        //         let schemeDetails = data.data.data.details
        //         for (let i = 0; i < schemeDetails.length; i++) {
        //             schemeDetails[i]["label"] = schemeDetails[i].name
        //             schemeDetails[i]["value"] = schemeDetails[i].name
        //         }
        //         this.setState({ schemeDetails }, () => {
        //             this.setSchemeCouponList()
        //         });
        //         console.log(data.data.data.details, "data1233444");

        //         // console.log(data.data.data.response[0],"discountdata");
        //         // this.setState({ discountvalue: data.data.data.response[0].discountValue });
        //         // this.setState({ discountvalue: 0 });
        //     }
        // })

        CountryService.getCountries().then(data => {
            if (data.success) {
                this.setState({ countries: data.countries })
            }
        })

        setTimeout(() => {
            this.populateStates("IN")
            // this.populateStates(countryObj.isoCode)
        }, 500);
    }

    setSchemeCouponList = () => {
        console.log(this.state.couponList, this.state.schemeDetails, 'data2222565656565');
        let schemeCouponList = [...this.state.couponList, ...this.state.schemeDetails]
        console.log(schemeCouponList, "data2222schemeCouponList");
        setTimeout(() => {
            this.setState({ schemeCouponList })
        }, 500);
    }

    navigateToPrevious() {
        this.props.navigate(-1);
    }

    getCouponCode = async (data) => {
        let code = ""
        if (data && data.hasOwnProperty('scheme_code')) {
            // setTimeout(() => {
            console.log('scheme')
            this.setState({ coupon_code: data.scheme_code })

            // }, 500);


        } else {
            OtherService.getCouponCode(data).then(data => {
                console.log(data.data.data.coupondetails.length, 'coupondetails')
                if (data.data.success && data.data.data.coupondetails.length) {
                    console.log(data.data.data.coupondetails[0].coupon_id, 'coupondetails11')
                    console.log('coupon')
                    let couponid = data.data.data.coupondetails[0].coupon_id;
                    // alert(couponid);
                    // setTimeout(() => {
                    this.setState({ coupon_code: couponid })

                    // }, 500);
                }
            })
        }

        // return code;


    }

    async placeOrder() {
        let filteredData = [];
        await this.getCouponCode(this.state.selectedValue);
        const prescription_id = this.props.CustomerReducer.prescription?._id;
        console.log(prescription_id, "prescription");
        // return
        const customer_id = this.props.CustomerReducer.customer?._id;
        const customer = this.props.CustomerReducer.customer;

        let pagename = this.props.CustomerReducer.pagename;
        console.log(customer, 3333);

        // return
        const { paymentOrder, products, product_quantities, productQuantity, registration, isChecked, freepenQuantity, freepenPrice, isCheckFreePen, selectedSchemeProduct, titleName, titleName_2, image, selectedFile, is_applied, is_applied_2, selectedSchemeProduct_2, freepenQuantity_2, freepenPrice_2, isCheckFreePen_2, } = this.state;



        console.log(freepenQuantity, freepenPrice, selectedSchemeProduct, "freepenQuantity, freepenPrice");
        // return
        console.log(freepenQuantity, freepenPrice, "freepenQuantity, freepenPrice");
        console.log(productQuantity, "allproductquantity");
        console.log(products, "allproducts");
        console.log(paymentOrder, "allproducts123");
        console.log(product_quantities, "product_quantities");
        console.log(registration.addressLine1, "addressLine1")
        const newProducts = products.map((product, i) => {
            return {
                product_id: product._id,
                price: product.price,
                quantity: product_quantities[i].quantity,
                // quantity: product.quantity,
                name: product.name
            }
        })
        const _products = newProducts.filter(product => product.quantity != 0)

        console.log(_products, "_products");
        const doctorid = customer ? customer.doctor_id : ""


        console.log(this.state.selectedValue, 200000);
        // let selectedData = this.state.selectedValue;

        let couponcode = ''
        let coupontype = ''
        let couponcode_2 = ''
        let coupontype_2 = ''
        if (this.state.selectedValue && this.state.selectedValue.hasOwnProperty('scheme_code')) {
            // setTimeout(() => {
            console.log('scheme1233')
            couponcode = this.state.selectedValue.scheme_code
            coupontype = 'scheme'
            this.setState({ coupon_code: this.state.selectedValue.scheme_code })
            // }, 500);
        } else {
            const data = await OtherService.getCouponCode(this.state.selectedValue);
            if (data.data.success && data.data.data.coupondetails.length) {
                couponcode = data.data.data.coupondetails[0].coupon_id;
                coupontype = 'coupon'
                this.setState({ coupon_code: couponcode });
            }
        }

        if (this.state.selectedValue_2 && this.state.selectedValue_2.hasOwnProperty('scheme_code')) {
            // setTimeout(() => {
            console.log('scheme1233')
            couponcode_2 = this.state.selectedValue_2.scheme_code
            coupontype_2 = 'scheme'
            this.setState({ coupon_code: this.state.selectedValue_2.scheme_code })
            // }, 500);
        } else {
            const data = await OtherService.getCouponCode(this.state.selectedValue_2);
            if (data.data.success && data.data.data.coupondetails.length) {
                couponcode_2 = data.data.data.coupondetails[0].coupon_id;
                coupontype_2 = 'coupon'
                this.setState({ coupon_code: couponcode_2 });
            }
        }

        // alert(couponcode)



        let submittedData = {
            customer_id,
            total: paymentOrder.finalPaymentAfterDiscount,
            applieddiscount: paymentOrder.appliedDiscount,
            discountAmount: paymentOrder.discountamount,
            totalBeforeDiscount: paymentOrder.totalPayment,
            couponCode: is_applied ? (this.state.selectedValue ? this.state.selectedValue.name : "") : "",
            couponCodeId: is_applied ? couponcode : "",
            couponType: is_applied ? coupontype : "",
            couponCode_2: is_applied_2 ? (this.state.selectedValue_2 ? this.state.selectedValue_2.name : "") : "",
            couponCodeId_2: is_applied_2 ? couponcode_2 : "",
            couponType_2: is_applied_2 ? coupontype_2 : "",
            couponDiscount: this.state.couponDiscount,
            products: _products,
            doctor_id: doctorid,
            prescription_id: prescription_id,
            addressLine1: registration.addressLine1,
            pin: registration.pin,
            country: registration.country,
            state: registration.state,
            town: registration.town,
            isChecked: isChecked,
            freepenQuantity: isCheckFreePen ? freepenQuantity : 0,
            freepenPrice: isCheckFreePen ? freepenPrice : 0,
            freepenQuantity_2: isCheckFreePen_2 ? freepenQuantity_2 : 0,
            freepenPrice_2: isCheckFreePen_2 ? freepenPrice_2 : 0,
            selectedSchemeProduct: selectedSchemeProduct.length > 0 ? selectedSchemeProduct.join(',') : "",
            selectedSchemeProduct_2: selectedSchemeProduct_2.length > 0 ? selectedSchemeProduct_2.join(',') : "",
            titleName: titleName,
            titleName_2: titleName_2

        }

        console.log(submittedData, "submittedData");
        // return

        let all_products = this.state.products
        let all_quantities = {}
        let product_length = all_products.length
        for (let i = 0; i < product_length; i++) {
            all_quantities[`quantity${i + 1}`] = 0
        }
        for (let i = 0; i < _products.length; i++) {
            if (_products.length === 1) {
                all_quantities[`quantity${i + 1}`] = productQuantity[_products[i].product_id];
                for (let j = 0; j < product_length; j++) {
                    if (j !== 0) {
                        all_quantities[`quantity${i + 1}`] = 0
                    }
                }
            }
            else {
                if (_products[i] && productQuantity[_products[i].product_id]) {
                    all_quantities[`quantity${i + 1}`] = productQuantity[_products[i].product_id];
                }
            }
        }
        console.log(all_quantities, "allquant");
        console.log(customer, 'customer>>>>><<<<<<')
        //////////////////////////ayan///////////////////////////////////
        // submittedData = {
        //     ...submittedData,
        //     doctor_id: "64d51d951ed9ac3e2948c3f7",
        //     prescription_id: "64d51d951ed9ac3e2948c3e9",
        //     products: [{
        //         product_id: "65249d69d0f0d5b703cc417a",
        //         price: 3280,
        //         quantity: 10,
        //         name: "EMROK - Injection (I.V) 800mg/100ml"
        //     }],
        //     total: 32800,
        //     totalBeforeDiscount: 32800
        // }

        //////////////////////////ayan////////////////////////////////////
        console.log(submittedData, 'bbbbbbbbbbbbbbbbb>>>>><<<<<<')
        console.log(registration, "registration");
        // return
        if (!submittedData.products.length) {
            this.props.hoc.customAlert("Please select atleast 1 product", false)
        }
        else if ((pagename != "" && pagename == 'Order') && (image == null || image == false)) {
            this.props.hoc.customAlert("Please upload prescription", false)
        }
        else if (isChecked == true && registration.addressLine1 == "") {
            this.props.hoc.customAlert("Address is required", false)
            return false
        } else if (isChecked == true && registration.pin == "") {
            this.props.hoc.customAlert("Pin Code is required", false)
            return false
        } else if (isChecked == true && registration.country == "") {
            this.props.hoc.customAlert("Country is required", false)
            return false
        } else if (isChecked == true && registration.state == "") {
            this.props.hoc.customAlert("State is required", false)
            return false
        } else if (isChecked == true && registration.town == "") {
            this.props.hoc.customAlert("Town is required", false)
            return false
        } else {

            console.log(pagename, "data !!!!!!!!!!!!file pathh123");
            console.log(image, "data !!!!!!!!!!!!file pathh123");

            if ((pagename == 'Order' && image != null)) {

                let payload = new FormData()
                payload.append("customer_id", customer_id || null)
                payload.append("doctor_id", doctorid ? doctorid : null)
                payload.append("prescription", selectedFile)

                CustomerService.uploadPrescription(payload).then(data => {
                    console.log(data.data.data, "data !!!!!!!!!!!!file pathh");

                    this.setState({ isLoading: false });
                    submittedData['prescription_id'] = data.data.data._id

                    let test = {
                        ...this.props.CustomerReducer.customer,
                        prescription: {
                            ...this.props.CustomerReducer.customer.prescription,
                            // Modify the prescription data here

                            _id: data.data.data._id,
                            prescription_code: data.data.data.prescription_code,
                            doctor_id: data.data.data.doctor_id,
                            customer_id: data.data.data.customer_id,
                            filepath: data.data.data.filepath,
                            deleted_at: null,
                            createdAt: data.data.data.createdAt,
                            updatedAt: data.data.data.updatedAt,
                            __v: 0

                        }
                    };

                }).catch(err => {
                    this.setState({ isLoading: false });
                    console.log(err, "err")
                })
            } else {
                console.log('data !!!!!!!!!!!!file pathh123')
            }
            console.log(submittedData, 'submittedData22')
            // return
            CustomerService.createOrder(submittedData).then(res => {
                if (res && res.data && res.data.success && res.data.data) {
                    CallCenterService.showLoader()
                    console.log("order details", res.data.data[0]);
                    // return
                    let orderContent = {
                        _id: res.data.data[0]._id
                    }

                    // setTimeout(() => {
                    this.props.placeOrder(res.data.data[0]);
                    this.props.setIsSuccess(null);

                    // }, 500);

                    AdminService.getstateStockistMapping().then(async response => {
                        console.log(res, res.data.data[0].address.state, 7878);
                        if (response.data.success) {
                            filteredData = response.data.data.mapping.filter((item) => item.state_name.toLowerCase() === res.data.data[0].address.state.toLowerCase());
                            console.log(filteredData, "filteredData12");
                            if (filteredData.length > 0) {
                                this.setState({ isOrderplaced: true, orderDetails: res.data.data[0] })
                                CallCenterService.hideLoader()
                            } else {

                                setTimeout(() => {
                                    this.setPaymentDetails({ order_id: res.data.data[0].order_id, amount: res.data.data[0].total, billing_name: (res.data.data[0].customer ? res.data.data[0].customer.name : ""), billing_address: (res.data.data[0].address ? res.data.data[0].address.addressLine1 : ""), billing_city: (res.data.data[0].address ? res.data.data[0].address.town : null), billing_state: (res.data.data[0].address ? res.data.data[0].address.state : ""), billing_zip: (res.data.data[0].address ? res.data.data[0].address.pin : ""), billing_country: (res.data.data[0].address ? res.data.data[0].address.country : ""), billing_tel: (res.data.data[0].customer ? res.data.data[0].customer.mobileno : ""), billing_email: (res.data.data[0].customer ? res.data.data[0].customer.email : "") })
                                    // this.setPaymentDetails({ order_id: res.data.data[0].order_id, amount: res.data.data[0].total })
                                }, 5000);

                            }
                        }
                    }).catch(err => {
                        //console.log(err)
                    })
                    // if (res.data.data.length > 0 && (res.data.data[0].address.state === "Maharashtra" || res.data.data[0].address.state === "Goa")) {
                    //return
                    //this.setState({ isOrderplaced: true, orderDetails: res.data.data[0] })

                    // OtherService.getOrderDetailsOut().then(async res => {
                    //     // //console.log(res, 7878);
                    //     if (res && res.data && res.data.success) {
                    //         //console.log(res, 123456);
                    //     }
                    // }).catch(err => {
                    //     //console.log(err)
                    // })


                    // this.props.hoc.customAlert("Town is required", false)
                    // this.props.navigate('/customer/payment');
                }
            }).catch(err => {
                CallCenterService.hideLoader()
                console.log(err, 67676776)
            })
        }
        //this.props.navigate('/customer/payment');
    }
    productIncrement = async (productId) => {
        const { products, productQuantity, schemeDetails, isCheckFreePen, selectedValue, selectedValue_2, selectedSchemeProduct, selectedSchemeProduct_2 } = this.state;
        // alert(selectedSchemeProduct);
        // alert(selectedSchemeProduct_2)
        // console.log(products, "productsproductQuantity");
        // console.log(productQuantity, "productQuantity")
        let product_quantities = this.state.product_quantities
        // console.log(product_quantities, "product_quantitiesproductQuantity");
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })
        console.log(products[productIndex], "products[productIndex]");
        if (products[productIndex].material == '404706' && selectedSchemeProduct != "") {
            this.props.hoc.customAlert('Please note that for changing the product quantity you need to first remove the applied scheme on the particular product and then continue by selecting the correct product quantity to place your order.');
            return false;
        }
        if (products[productIndex].material == '403820' && selectedSchemeProduct_2 != "") {
            this.props.hoc.customAlert('Please note that for changing the product quantity you need to first remove the applied scheme on the particular product and then continue by selecting the correct product quantity to place your order.');
            return false;
        }
        //get scheme and coupon
        this.getSchemeAndCoupon(products[productIndex]);
        // console.log(productIndex, "productIndexproductQuantity")
        // console.log(products[productIndex], "productdetailss12333productQuantity");
        // console.log(products[productIndex].quantity, "productIndexproductQuantity");
        // console.log(productQuantity, ">>>>>>productQuantity")
        // console.log(product_quantities, "655555productQuantity");
        // console.log(productIndex, "655555productQuantity11");
        // console.log(product_quantities, productIndex, product_quantities[productIndex].quantity, "655555productQuantity");
        if (products[productIndex].material == "403877") {
            // console.log('1_productQuantity');

            if (product_quantities[productIndex].quantity < productQuantity[productId] && product_quantities[productIndex].quantity < 10) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        } else {
            // console.log('2_productQuantity')

            if (products[productIndex].material == '403820') {
                // alert("11");
                console.log(product_quantities[productIndex].quantity, productQuantity[productId], "jkhjhjk");
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 6

                console.log(newProducts[productIndex].quantity, "newProducts[productIndex].quantity123");

                if (newProducts[productIndex].quantity > 12) {
                    newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 6
                    this.props.hoc.customAlert("Maximum order quantity is 12 units.", false)
                    return false;
                } else {
                    console.log(newProducts[productIndex].quantity, "newProducts[productIndex].quantity");

                    this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
                    if (product_quantities[productIndex].quantity > productQuantity[productId] || productQuantity[productId] <= PRODUCT_LIMIT) {
                        console.log("2_productQuantity");
                        this.props.hoc.customAlert("Product stock is not available.", false)
                        newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 6
                        console.log(newProducts[productIndex].quantity, "newProducts[productIndex].quantity")
                        if (newProducts[productIndex].quantity > MAXIMUM_ORDER_QUANTITY) {
                            this.props.hoc.customAlert("Maximum order quantity allowed 12.", false)
                        } else {
                            this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })

                        }
                    }
                    console.log(product_quantities[productIndex].quantity, productQuantity[productId], "jkhjhjk12333");


                    if (selectedValue_2.hasOwnProperty("increase_quantity")) {
                        console.log("calculate scheme");
                        // alert("1");
                        this.calculateScheme(products[productIndex].material)
                    } else {
                        // alert("2")
                        console.log("calculate scheme1");
                        this.productPriceCalCulate()

                        setTimeout(() => {
                            // this.calculateCoupon()

                        }, 500);
                    }
                }

            } else {
                // alert("123")
                // console.log(product_quantities[productIndex].quantity, productQuantity[productId], "jkhjhjk");
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 5

                console.log(newProducts[productIndex].quantity, "newProducts[productIndex].quantity123");

                if (newProducts[productIndex].quantity > 10) {
                    newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                    this.props.hoc.customAlert("Maximum order quantity is 10 units.", false)
                    return false;
                } else {
                    console.log(newProducts[productIndex].quantity, "newProducts[productIndex].quantity");

                    this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
                    if (product_quantities[productIndex].quantity > productQuantity[productId] || productQuantity[productId] <= PRODUCT_LIMIT) {
                        console.log("2_productQuantity");
                        this.props.hoc.customAlert("Product stock is not available.", false)
                        newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                        console.log(newProducts[productIndex].quantity, "newProducts[productIndex].quantity")
                        if (newProducts[productIndex].quantity > MAXIMUM_ORDER_QUANTITY) {
                            this.props.hoc.customAlert("Maximum order quantity allowed 10.", false)
                        } else {
                            this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })

                        }
                    }
                    console.log(product_quantities, productQuantity, newProducts, products, "jkhjhjk12333");
                    if (selectedValue.hasOwnProperty("increase_quantity")) {
                        console.log("calculate scheme");
                        this.calculateScheme(products[productIndex].material)
                    } else {
                        console.log("calculate scheme1");
                        this.productPriceCalCulate()

                        setTimeout(() => {
                            // this.calculateCoupon()

                        }, 500);
                    }
                }
            }



        }

    }

    productDecrement = async (productId) => {
        const { products, schemeDetails, selectedValue, couponListOfAllProduct, schemeListOfAllProduct, couponList, couponListFinal, schemeCouponList, selectedValue_2, selectedSchemeProduct, selectedSchemeProduct_2 } = this.state;
        let product_quantities = this.state.product_quantities
        let finalData = []
        console.log(products, couponList, couponListFinal, schemeDetails, 'aaaaaaaaaaaa')
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })

        if (products[productIndex].material == '404706' && selectedSchemeProduct != "") {
            this.props.hoc.customAlert('Please note that for changing the product quantity you need to first remove the applied scheme on the particular product and then continue by selecting the correct product quantity to place your order.');
            return false;

        }

        if (products[productIndex].material == '403820' && selectedSchemeProduct_2 != "") {
            this.props.hoc.customAlert('Please note that for changing the product quantity you need to first remove the applied scheme on the particular product and then continue by selecting the correct product quantity to place your order.');
            return false;
        }


        if (products[productIndex].material == "403877") {
            if (product_quantities[productIndex].quantity > 0) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        } else {
            console.log(product_quantities[productIndex].quantity);
            if (product_quantities[productIndex].quantity > 0) {
                if (products[productIndex].material == "403820") {
                    newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 6
                } else {
                    newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                }

                if (newProducts[productIndex].quantity == 0) {
                    let selectedMaterial = products[productIndex].material;
                    this.setState(prevState => {
                        const updatedCouponList = prevState.couponListOfAllProduct.filter(
                            coupon => coupon.material !== selectedMaterial
                        );

                        return { couponListOfAllProduct: updatedCouponList };
                    });

                    this.setState(prevState => {
                        const updatedSchemeList = prevState.schemeListOfAllProduct.filter(
                            coupon => coupon.material !== selectedMaterial
                        );

                        return { schemeListOfAllProduct: updatedSchemeList };
                    });

                    setTimeout(() => {

                        this.setState(prevState => {
                            const updatedCouponList_1 = prevState.couponList.filter(
                                coupon => coupon.material !== selectedMaterial
                            );

                            console.log(updatedCouponList_1, "updatedCouponList_1");

                            return { couponList: updatedCouponList_1 };
                        });

                        this.setState(prevState => {
                            const updatedCouponListFinal_1 = prevState.couponListFinal.filter(
                                coupon => coupon.material !== selectedMaterial
                            );
                            return { couponListFinal: updatedCouponListFinal_1 };
                        });
                        this.setState(prevState => {
                            const updatedSchemeList_1 = prevState.schemeDetails.filter(
                                coupon => coupon.material !== selectedMaterial
                            );
                            return { schemeDetails: updatedSchemeList_1 };
                        });
                        this.setState(prevState => {
                            const updatedschemeCouponList = prevState.schemeCouponList.filter(
                                coupon => coupon.material !== selectedMaterial
                            );
                            return { schemeCouponList: updatedschemeCouponList };
                        });

                    }, 500);

                }


                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })

                if (products[productIndex].material == "403820") {
                    if (selectedValue_2.hasOwnProperty("increase_quantity")) {
                        console.log('in else product decrement 1');

                        this.calculateScheme(products[productIndex].material)
                    } else {
                        console.log('in else product decrement 2');
                        this.productPriceCalCulate()

                        setTimeout(() => {
                            // this.calculateCoupon()

                        }, 500);
                    }
                } else {
                    if (selectedValue.hasOwnProperty("increase_quantity")) {
                        console.log('in else product decrement 1');

                        this.calculateScheme(products[productIndex].material)
                    } else {
                        console.log('in else product decrement 2');
                        this.productPriceCalCulate()

                        setTimeout(() => {
                            // this.calculateCoupon()

                        }, 500);
                    }
                }

            } else {
                // alert();






                // console.log('in else product decrement');
                // this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedSchemeProduct: [] })
                // this.setState({ couponDiscount: "" });
                // setTimeout(() => {
                //     this.setState({ selectedValue: "" })
                // }, 500);
                // this.setState({ selectedValue: "" });
            }
        }



        // const materialId = products ? products[0].material : null;
        // const selectedQuantity = product_quantities ? product_quantities[0].quantity : 0;
        // const selectedProduct = schemeDetails.find((schemeDetail) => {
        //     return schemeDetail.material == materialId && selectedQuantity >= schemeDetail.purchase_quantity
        // })
        // if (selectedProduct) {
        //     let total_free_quantity = (Math.floor(product_quantities[productIndex].quantity / selectedProduct.purchase_quantity)) > 0 ? (Math.floor(product_quantities[productIndex].quantity / selectedProduct.purchase_quantity)) * selectedProduct.free_quantity : 0
        //     let total_sum = total_free_quantity > 0 ? total_free_quantity * selectedProduct.price : 0
        //     this.setState({ freepenQuantity: total_free_quantity, freepenPrice: total_sum })
        // } else {
        //     this.setState({ freepenQuantity: 0, freepenPrice: 0 })
        // }
        // console.log(product_quantities, "product_quantities");
    }

    getSchemeAndCoupon = async (payload) => {
        let finalData = [];
        ProductService.getSchemeAndCoupon(payload).then(data => {
            if (data.success) {
                console.log(data, "data2222")
                if (data.data.data.couponList.length > 0) {
                    this.setState(prevState => {
                        const updatedCouponList = [
                            ...prevState.couponListOfAllProduct,
                            ...data.data.data.couponList
                        ];

                        // Remove duplicates based on materialId
                        const uniqueCoupons = updatedCouponList.filter(
                            (coupon, index, self) =>
                                index === self.findIndex(c => c.material === coupon.material && c.code === coupon.code)
                        );

                        return { couponListOfAllProduct: uniqueCoupons };
                    });
                }
                if (data.data.data.schemeList.length > 0) {
                    this.setState(prevState => {
                        // Create a Map to overwrite existing entries
                        const schemeMap = new Map();

                        // Add previous state schemes to the map
                        prevState.schemeListOfAllProduct.forEach(scheme => {
                            schemeMap.set(`${scheme.material}-${scheme.scheme_code}`, scheme);
                        });

                        // Add new schemes to the map (overwriting existing ones)
                        data.data.data.schemeList.forEach(scheme => {
                            schemeMap.set(`${scheme.material}-${scheme.scheme_code}`, scheme);
                        });

                        // Convert map values back to an array
                        const updatedSchemeList = Array.from(schemeMap.values());

                        console.log(updatedSchemeList, 'data2222updatedSchemeList');

                        return { schemeListOfAllProduct: updatedSchemeList };
                    });
                }

                setTimeout(() => {
                    if (this.state.couponListOfAllProduct.length > 0) {

                        for (let index = 0; index < this.state.couponListOfAllProduct.length; index++) {
                            finalData[index] = {
                                "name": this.state.couponListOfAllProduct[index].code,
                                "label": this.state.couponListOfAllProduct[index].code,
                                "value": this.state.couponListOfAllProduct[index].code,
                                "id": this.state.couponListOfAllProduct[index]._id,
                                "material": this.state.couponListOfAllProduct[index].material
                            };
                        }
                        this.setState({ couponList: finalData, couponListFinal: this.state.couponListOfAllProduct }, () => {
                            this.setSchemeCouponList()
                        })
                    }

                    if (this.state.schemeListOfAllProduct.length > 0) {
                        let schemeDetails = this.state.schemeListOfAllProduct
                        for (let i = 0; i < schemeDetails.length; i++) {
                            schemeDetails[i]["label"] = schemeDetails[i].name
                            schemeDetails[i]["value"] = schemeDetails[i].name
                        }
                        this.setState({ schemeDetails }, () => {
                            this.setSchemeCouponList()
                        });
                    }

                }, 500);

            }

        });
    }

    handleCheckbox = () => {
        console.log('checkbox');
        const currentVal = !this.state.isChecked
        this.setState({ isChecked: currentVal });
    }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name == 'pin' && value.length == 6) {
            let payload = {
                pincode: value
            }
            console.log(payload, "payload123");
            OtherService.getStateAndCity(payload).then(async res => {

                console.log(res.data.data.details, "getStateAndCity");
                if (res && res.data && res.data.success) {
                    let _registration = this.state.registration
                    _registration["state"] = res.data.data.details.stateName
                    _registration["town"] = res.data.data.details.cityName
                    this.setState({ registration: _registration })

                    setTimeout(() => {
                        this.populateStates("IN")
                        this.populateTowns(res.data.data.details.countryCode, res.data.data.details.isoCode)
                    }, 500);

                }
            }).catch(err => {
                //console.log(err)
            })

        } else if (name == 'pin' && value.length == 0) {
            let _registration = this.state.registration
            _registration["state"] = ""
            _registration["town"] = ""
            this.setState({ registration: _registration })
            setTimeout(() => {
                this.populateStates("IN")
                // this.populateTowns(res.data.data.details.countryCode, res.data.data.details.isoCode)
            }, 500);

        }
        const registration = { ...this.state.registration };
        console.log(registration, "registration12335");
        registration[name] = value;
        this.setState({ registration: registration });
        // this.populateTowns(res.data.data.details.countryCode, res.data.data.details.isoCode)
        console.log(registration, "registration1233");
    }

    changeDropdownValue = (event) => {

        // alert(event.value);
    }
    setStateRegistrationData = (data) => {
        this.setState({ registration: data });

    }
    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.registration.state || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))
                }
                // }, 2000);
            })
        }
    }

    setPaymentDetails = (payload) => {
        console.log(payload, "paymentDetails");
        const { paymentProcess } = this.state
        CallCenterService.showLoader()
        CCAvenueService.generateChecksum(payload).then(data => {
            console.log(data, "sdfsd");
            if (data.success) {
                this.setState({ paymentProcess: true })
                setTimeout(() => {
                    CallCenterService.hideLoader()
                    $("#nonseamless").submit()
                }, 2000);
            }
            else {
                CallCenterService.hideLoader()
            }
        })
    }

    handleFreePenCHeck = () => {
        // alert();
        let product_quantities = this.state.product_quantities
        let isCheckFreePen = !this.state.isCheckFreePen
        console.log(product_quantities, 'aaaaaaaaaaaaproduct_quantities')

        if (isCheckFreePen) {
            let productSelectedQuantity = product_quantities.some(item => item.quantity > 0);

            if (productSelectedQuantity) {
                this.setState({ freepenQuantity: FREE_PEN_QUANTITY, freepenPrice: FREE_PEN_PRICE })
            } else {
                this.setState({ freepenQuantity: 0, freepenPrice: 0 })
            }
            console.log(productSelectedQuantity, "productSelectedQuantity");

        } else {
            this.setState({ freepenQuantity: 0, freepenPrice: 0 })
        }
        this.setState({ isCheckFreePen: isCheckFreePen })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    toggleLightbox() {
        this.setState(prevState => ({
            openLightbox: !prevState.openLightbox,
        }));
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ selectedFile: file, fileUploadError: '', imagename: "" }, () => {
                this.handleUpload()
            });
        }
    };

    handleButtonClick = () => {

        // Programmatically trigger the file input click event
        this.fileInputRef.current.click();
    };

    handleUpload = async () => {
        console.log(this.state.isLoading, "loading status");

        const MaxFileSize = 10 * 1024 * 1024; // 10MB in bytes
        // Handle the file upload logic here
        const { selectedFile } = this.state;

        console.log(selectedFile);

        if (selectedFile) {
            this.setState({ isLoading: true });
            const fileType = selectedFile.type;
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf') {

                this.setState({ isLoading: false });
                this.setState({ fileUploadError: 'Only JPG, PNG, and PDF files are allowed.', color: "red" });

                return;
            }
            else if (fileSizeMB > 25) {
                this.setState({ isLoading: false });
                this.setState({ fileUploadError: 'File size must be less than 25MB.', color: "red" });

                return;
            }
            else {
                const reader = new FileReader()
                // reader.onloadend = () => {
                //     console.log(reader, 7777)
                //     this.setState({ image: reader.result })
                // }
                reader.onload = (e) => {
                    this.setState({ image: e.target.result });
                };
                reader.readAsDataURL(selectedFile)
                // let newFile = await new File([file], this.state.product._id + '.' + file.name.split('.')[file.name.split('.').length - 1], { type: file.type })
                // this.setState({ selectedFile: newFile, fileUploadError: '', imagename: '' }, () => {
                //     // this.handleUpload()
                // });

                let payload = new FormData()
                // payload.append("customer_id", this.props.CustomerReducer.customer._id || null)
                // payload.append("doctor_id", this.props.CustomerReducer.customer.prescribedDoctor._id || null)
                payload.append("prescription", selectedFile)

                // console.log(selectedFile, 66666);

                this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: selectedFile.name, selectedFile: selectedFile });
                //console.log('File to upload:', selectedFile, this.props.CustomerReducer.customer, payload);
                // CustomerService.uploadPrescription(payload).then(data => {
                //     console.log(data.data.data.filepath, "data !!!!!!!!!!!!file pathh");
                //     this.getPrescription()
                //     this.setState({ isLoading: false });
                //     this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: data.data.data.filepath });
                // }).catch(err => {
                //     this.setState({ isLoading: false });
                //     console.log(err, "err")
                // })
            }
        } else {
            this.setState({ fileUploadError: 'Please select a file first.' });
        }
    };

    // Function to fetch PDF from URL and convert to Base64
    fetchPdfAndConvertToBase64 = async (url) => {
        try {
            console.log(url, 88888999);
            const response = await fetch(url, { mode: 'no-cors' });
            const blob = await response.blob(); // Get the PDF as a Blob

            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ base64: reader.result }); // Base64 string
            };

            reader.readAsDataURL(blob); // Convert Blob to base64
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    render = () => {

        const { products, productQuantity, paymentOrder, product_quantities, couponList, couponDiscount, isShowApplyBtn, isChecked, registration, countries, country, state, town, states, towns, showTowns, isOrderplaced, orderDetails, paymentDetails, isCheckFreePen, freepenQuantity, freepenPrice, schemeCouponList, titleName, selectedSchemeProduct, fileUploadError, color, imagename, isLoading, image, openLightbox, selectedValue, couponListOfAllProduct, schemeListOfAllProduct, selectedValue_2, isShowApplyBtn_2, selectedSchemeProduct_2, titleName_2, freepenPrice_2, freepenQuantity_2, isCheckFreePen_2 } = this.state;
        console.log(products, "kajshdjkhas"); console.log(productQuantity, "kajshdjkhas");
        console.log(this.state, 999999);
        console.log(country, 'country');
        console.log(isOrderplaced, "isOrderplaced");
        console.log(selectedSchemeProduct, "selectedSchemeProduct")
        let customerReducerDetails = this.props.CustomerReducer;
        console.log(customerReducerDetails.pagename, "customerReducerDetails");

        console.log(selectedValue, isShowApplyBtn, 6666666)
        console.log(paymentOrder, "paymentOrder11")
        // return
        // let pagenameDetails = customerReducerDetails ? customerReducerDetails.pagename : ""
        // console.log(pagenameDetails, "pagename123");

        return (
            <>
                <section className="place-order-container">
                    <div className="container">

                        <CustomerHeader />
                        {/* Row End*/}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Title end */}
                                {/* Product details content box end*/}
                                {/* Product details content box end*/}
                                {/* commented by ayan */}
                                <ProductList paymentOrder={paymentOrder} products={products} productQuantity={productQuantity} productIncrement={this.productIncrement} productDecrement={this.productDecrement} product_quantities={product_quantities} reload={this.state.reload} couponListOfAllProduct={couponListOfAllProduct} schemeListOfAllProduct={schemeListOfAllProduct} schemeCouponList={schemeCouponList} selectedValue={selectedValue} onValueChange={this.handleSelectChange} applyCoupon={this.applyCoupon} couponDiscount={couponDiscount} isShowApplyBtn={isShowApplyBtn} removeCoupon={this.removeCoupon} selectedValue_2={selectedValue_2} isShowApplyBtn_2={isShowApplyBtn_2} />
                                {/* <div className="row mt-4">
                                    <div className='col-md-1 checkboxstyle'>
                                        <input type="checkbox" className='form-label' onChange={() => this.handleFreePenCHeck()} checked={isCheckFreePen} />
                                    </div>
                                    <div className='col-md-10 consenttext' >
                                        <p>Please confirm if you want a Free Pen worth Rs.800/- with your Order.</p>
                                    </div>
                                </div> */}

                                {customerReducerDetails.pagename && customerReducerDetails.pagename == 'Order' ? <div className="regstr-form mt-4 mb-4">
                                    <input
                                        type="file"
                                        ref={this.fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={this.handleFileChange}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}

                                    />
                                    <button type="button" className="upld-prscrbtn-pt" id onClick={this.handleButtonClick}>
                                        {this.state.imagename ? "Change Your Prescription" : "Upload Your Prescription"}
                                    </button>
                                    <p style={{ fontSize: 11, paddingTop: 10, textAlign: 'right', color: "#0a4f73", marginRight: 5 }}> Prescription needs to be uploaded in either jpeg, png and pdf format within a maximum limit of 25 MB <span className='requiredFld'> * </span></p>

                                    {fileUploadError && <div style={{ color, textAlign: 'right', marginRight: 20 }}>{fileUploadError}</div>}
                                    {
                                        image && imagename && imagename.split('.').pop().toLowerCase() !== 'pdf' ?
                                            <div className="col-md-4 col-upload-image">
                                                <div className="upload-image-box" onClick={this.toggleLightbox}>
                                                    <img
                                                        className="prescriptionimage"
                                                        src={image ? image : `${config.serviceUrl}/pres-images/${this.props.CustomerReducer.customer.prescription.filepath}`}
                                                        alt="Selected"
                                                    />
                                                </div>
                                                {openLightbox && (
                                                    <Lightbox
                                                        medium={image ? image : `${config.serviceUrl}/pres-images/${this.props.CustomerReducer.customer.prescription.filepath}`}
                                                        large={image ? image : `${config.serviceUrl}/pres-images/${this.props.CustomerReducer.customer.prescription.filepath}`}
                                                        alt="Selected Image"
                                                        onClose={this.toggleLightbox}
                                                    />
                                                )}
                                            </div> :
                                            (
                                                image ? (
                                                    <div style={{ height: '400px' }}>
                                                        <PdfViewerWithPlugin pdfFile={image ? image : this.fetchPdfAndConvertToBase64(`${config.serviceUrl}/pres-images/${this.props.CustomerReducer.customer.prescription.filepath}`)} />
                                                    </div>
                                                ) : (
                                                    <p>No file selected</p>
                                                )
                                            )

                                    }
                                    {
                                        image &&
                                        <button type="button" style={{ width: '200px' }} className="btn btn-white form-control upload-btn clear-sec-btn me-2 mt-4" onClick={() => {
                                            this.setState({ image: null, imagename: "", fileUploadError: "" })
                                        }}>
                                            Clear Selection
                                        </button>
                                    }

                                    {this.state.imagename != "" && <p>Selected file: {this.state.imagename}</p>}
                                </div> : ""}

                            </div>
                            <div className="col-lg-12">
                                {/* commented my ayan */}
                                <OrderList productListCompRef={this.productListCompRef} products={products} paymentOrder={paymentOrder} placeOrder={this.placeOrder} product_quantities={product_quantities} couponList={couponList} selectedValue={selectedValue} onValueChange={this.handleSelectChange} applyCoupon={this.applyCoupon} couponDiscount={couponDiscount} isShowApplyBtn={isShowApplyBtn} removeCoupon={this.removeCoupon} handleCheckbox={this.handleCheckbox} isChecked={isChecked} changeVal={this.changeVal} addressLine1={registration.addressLine1} pin={registration.pin} countries={countries} country={registration.country} states={states} towns={towns} town={registration.town} state={registration.state} setStateRegistrationData={this.setStateRegistrationData} populateStates={this.populateStates} populateTowns={this.populateTowns} showTowns={showTowns} registration={registration} freepenQuantity={freepenQuantity} freepenPrice={freepenPrice} isCheckFreePen={isCheckFreePen} schemeCouponList={schemeCouponList} titleName={titleName} selectedSchemeProduct={selectedSchemeProduct} selectedValue_2={selectedValue_2} isShowApplyBtn_2={isShowApplyBtn_2} titleName_2={titleName_2} selectedSchemeProduct_2={selectedSchemeProduct_2} freepenQuantity_2={freepenQuantity_2} freepenPrice_2={freepenPrice_2} isCheckFreePen_2={isCheckFreePen_2} />
                            </div>
                        </div>
                        {/* Row end*/}


                    </div>

                </section>
                {
                    isOrderplaced &&
                    <OrderPlacedModal currentOrder={orderDetails} navigateUrl={"/order-information"} />
                }

                {/* Logout btn top section end */}
                {
                    paymentDetails && paymentDetails.dataObj &&
                    <form id="nonseamless" method="post" name="redirect" action={config.ccavenueRedirectURL}>
                        <input type="hidden" id="encRequest" name="encRequest" value={paymentDetails.dataObj.encRequest} />
                        <input type="hidden" name="access_code" id="access_code" value={paymentDetails.dataObj.access_code} />
                        <script language="javascript">document.redirect.submit();</script>
                    </form>
                }
                <CustomerFooter />
            </>
        )

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        placeOrder: (orders) => dispatch(OrderAction.placeOrder(orders)),
        setIsSuccess: (issuccess) => dispatch(OrderAction.setIsSuccess(issuccess))
    }
}

const mapStateToProps = (state) => {
    console.log(state, "kjdskjfksd");
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        ProductReducer: state.ProductReducer,
        OrderReducer: state.OrderReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(PlaceOrder)))